import React from 'react';
import {Page} from '../components/Page';
import PageHeader from '../components/PageHeader';
import {Markdown} from '../components/Markdown';
import {Helmet} from "react-helmet";

export default function Support({pageContext: {support}}) {
	return <Page>
		<Helmet>
			<title>{support.titleSEO}</title>
			<meta name="description" content={support.descriptionSEO} />
	{/*		<link rel="canonical" href={'https://www.icit.com.au/support/' + support.slug}  />*/}
		</Helmet>
		<PageHeader>
			{support.header}
		</PageHeader>
		<Markdown>
			{support.bodyTextMain}
		</Markdown>
		{!!support.image && <img style={{maxWidth: '100%'}} src={support.image.localFile.publicURL} />}
		{!!support.file && <a href={support.file.localFile.publicURL} target="_blank">{support.file.name}</a>}
		<Markdown>
			{support.bodyTextMore}
		</Markdown>
	</Page>;
}
